import { KEY_NAME } from "@shared/Utils/key-names";
import { User } from "oidc-client-ts";
import { RefObject } from "preact";
import { useEffect } from "preact/hooks";
import { AuthState, CustomerUser, LoginStateValues } from "./customer-login-interfaces";

export const keyDownMenuHandler = (closeMenu: () => void, setButtonFocus: (state: boolean) => void) => {
  return (event: KeyboardEvent) => {
    if (event.key === KEY_NAME.Space) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.key === KEY_NAME.Space || event.key === KEY_NAME.Escape) {
      closeMenu();
      setButtonFocus(true);
    }
  };
};

export const mapState = (user: User | null, loginState: LoginStateValues) => {
  const customerUser: CustomerUser | null = user
    ? {
        firstName: user.profile.given_name,
        lastName: user.profile.family_name,
        initials: `${user.profile.given_name?.charAt(0)}${user.profile?.family_name?.charAt(0)}`,
        fullName: user.profile.name,
        isCompanyAdmin: false,
        userId: user.profile.sub,
      }
    : null;

  const state: AuthState = {
    user: customerUser,
    loginState: loginState,
    token: user != null ? user.access_token : "",
  };

  return state;
};

export const setReturnUrl = (returnUrl: string): void => {
  sessionStorage.setItem("iceweb:returnUrl", returnUrl);
};

export const removeReturnUrl = (): void => {
  sessionStorage.removeItem("iceweb:returnUrl");
};

const hasLoggedInKey = "iceweb:has-logged-In";
export const setHasBeenLoggedIn = (): void => {
  localStorage.setItem(hasLoggedInKey, "true");
};

export const getHasBeenLoggedIn = (): boolean => {
  return localStorage.getItem(hasLoggedInKey) === "true";
};

export const removeHasBeenLoggedIn = (): void => {
  localStorage.removeItem(hasLoggedInKey);
};

export const CloseWhenClickOutSideMenu = (container: RefObject<HTMLDivElement>, closeMenu: () => void) => {
  const closeMenuOnClick = (event: MouseEvent) => {
    if (!(event.target as HTMLElement).closest(".login-container")) {
      closeMenu();
    }
  };

  useEffect(() => {
    if (container?.current != null) {
      document.addEventListener("click", closeMenuOnClick);
    }

    return () => {
      if (container?.current != null) {
        document.removeEventListener("click", closeMenuOnClick);
      }
    };
  }, [container]);
};
