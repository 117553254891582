export const KEY_NAME = {
  Space: " ",
  End: "End",
  Home: "Home",
  PageDown: "PageDown",
  PageUp: "PageUp",
  Insert: "Insert",
  // Control Keys
  Tab: "Tab",
  Backspace: "Backspace",
  Enter: "Enter",
  Escape: "Escape",
  Delete: "Delete",
  // Modifier Keys
  Alt: "Alt",
  AltGraph: "AltGraph",
  Control: "Control",
  Shift: "Shift",
  Meta: "Meta",
  Fn: "Fn",
  // Arrow Keys
  ArrowDown: "ArrowDown",
  ArrowLeft: "ArrowLeft",
  ArrowRight: "ArrowRight",
  ArrowUp: "ArrowUp",
  // Function Keys
  F1: "F1",
  F2: "F2",
  F3: "F3",
  F4: "F4",
  F5: "F5",
  F6: "F6",
  F7: "F7",
  F8: "F8",
  F9: "F9",
  F10: "F10",
  F11: "F11",
  F12: "F12",
  // Lock Keys
  FnLock: "FnLock",
  CapsLock: "CapsLock",
  NumLock: "NumLock",
  ScrollLock: "ScrollLock",
  // Symbol Keys
  Equals: "=",
  Asterix: "*",
  Plus: "+",
  Minus: "-",
  ForwardSlash: "/",
  Dot: ".",
  Comma: ",",
  // Digit Keys
  Zero: "0",
  One: "1",
  Two: "2",
  Three: "3",
  Four: "4",
  Five: "5",
  Six: "6",
  Seven: "7",
  Eight: "8",
  Nine: "9",
  // Lower Case Letter Keys
  a: "a",
  b: "b",
  c: "c",
  d: "d",
  e: "e",
  f: "f",
  g: "g",
  h: "h",
  i: "i",
  j: "j",
  k: "k",
  l: "l",
  m: "m",
  n: "n",
  o: "o",
  p: "p",
  q: "q",
  r: "r",
  s: "s",
  t: "t",
  u: "u",
  v: "v",
  w: "w",
  x: "x",
  y: "y",
  z: "z",
  // Upper Case Letter Keys
  A: "A",
  B: "B",
  C: "C",
  D: "D",
  E: "E",
  F: "F",
  G: "G",
  H: "H",
  I: "I",
  J: "J",
  K: "K",
  L: "L",
  M: "M",
  N: "N",
  O: "O",
  P: "P",
  Q: "Q",
  R: "R",
  S: "S",
  T: "T",
  U: "U",
  V: "V",
  W: "W",
  X: "X",
  Y: "Y",
  Z: "Z",
} as const;

export type KeyName = (typeof KEY_NAME)[keyof typeof KEY_NAME];
