import { Fragment, h, JSX } from "preact";

export interface companyAdminOptions {
  minsideBaseUrl: string;
  hasCompanyAdmin: boolean;
}

export const ShowCompanyAdmin = ({ minsideBaseUrl, hasCompanyAdmin }: companyAdminOptions): JSX.Element => {
  return (
    <Fragment>
      {hasCompanyAdmin && (
        <li className="profile-menu__item">
          <a className="profile-menu__item__link" href={`${minsideBaseUrl}/minbedrift`} data-id="Min bedrift">
            <span className="profile-menu__icon">
              <svg style="width:4.8em;height:4.8em" focusable="false" aria-hidden="true">
                <use xlinkHref="#ice-company"></use>
              </svg>
            </span>
            <span className="link--underline-yellow">Til Min Bedrift</span>
          </a>
        </li>
      )}
    </Fragment>
  );
};
