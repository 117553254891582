import { IApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";

declare const window: {
  appInsights: IApplicationInsights | undefined;
} & Window;
export interface CustomDimensions {
  message: string;
  url?: string;
}

export class ApplicationInsightsService {
  trackException(exception: Error, message?: string): void {
    try {
      console.error("Unhandled exception", exception);
      const properties: CustomDimensions = {
        message: message,
        url: location.href,
      };
      window.appInsights?.trackException({ exception, severityLevel: SeverityLevel.Error, properties });
    } catch (error) {
      console.warn("Error calling application insights. Instrumentation key might not be set.");
    }
  }

  trackExceptionWarning(exception: Error, message?: string): void {
    try {
      console.error("Warning message", exception);
      const properties: CustomDimensions = {
        message: message,
        url: location.href,
      };
      window.appInsights?.trackException({ exception, severityLevel: SeverityLevel.Warning, properties });
    } catch (error) {
      console.warn("Error calling application insights. Instrumentation key might not be set.");
    }
  }
}
